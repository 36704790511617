import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const SvgSpriteIcon = ({ name, className, style }) => {
    const currentClassName = classNames('svg-icon', `svg-icon_${name}`, className)

    return (
        <svg className={currentClassName} style={style}>
            <use xlinkHref={`#${name}`} />
        </svg>
    )
}

SvgSpriteIcon.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
}

export default SvgSpriteIcon
